<template>
  <div v-if="flag" class="birth-day">
    <template v-for="(el, i) in peopleBirthList">
      <div class="birth-day__name" :key="i">
        {{ "\u{1F382}" }} {{ `${el.nickname.trim()},` }} {{ label }}
        <base-icon
          class="birth-day__close"
          :path="'Close.svg'"
          :screen-size-dependency="true"
          width="16"
          height="16"
          @click="hideTag(el.id)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";
import BaseIcon from "@/components/BaseIcon";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  name: "BirthDay",
  components: { BaseIcon },
  props: {
    date: Date,
    label: String,
    labelPosition: String,
  },

  data: function() {
    return {
      peopleBirthList: [],
      flag: Number,
    };
  },
  methods: {
    hideTag(tagId) {
      let hideTagList = loStorage.getObject("hideTagList") || [];
      if (!hideTagList.includes(tagId)) {
        hideTagList.push(tagId);
      }
      loStorage.saveObject("hideTagList", hideTagList);
      this.peopleBirthList = this.peopleBirthList.filter(item => !hideTagList.includes(item.id));
    },
  },
  async created() {
    this.flag = 0;
    this.peopleBirthList = await request("/api/user/listbirth");
    if (this.peopleBirthList) {
      this.peopleBirthList = this.peopleBirthList.filter(
        i =>
          parseInt(i.month) === new Date(this.date).getMonth() + 1 && parseInt(i.day) === new Date(this.date).getDate()
      );
      this.flag = this.peopleBirthList.length;
    }
    const hideTagList = loStorage.getObject("hideTagList");
    this.peopleBirthList = this.peopleBirthList.filter(item => !hideTagList.includes(item.id));
  },
};
</script>
<style lang="scss" scoped>
.birth-day {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto auto;
  gap: 24px;
  margin-left: 35px;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b2b2b2;
  }
  &__name {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: #f1f5f9;
    padding: 12px;
  }
  &__close {
    cursor: pointer;
    margin-left: 10px;
  }
  @media (max-width: 1440px) {
    & {
      margin-left: 24px;
    }
  }
  @media (max-width: 980px) {
    & {
      margin-left: 0;
    }
    &__name {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }
}
</style>
