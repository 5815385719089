<template>
  <div class="base-tabs">
    <div
      class="base-tabs__list"
      :class="{ active: activeTab === index }"
      v-for="(el, index) in baseTabsList"
      :key="index"
      @click="changeState(index, el)"
    >
      <div class="base-tabs__item" :class="{ active: activeTab === index }">
        {{ el }}
        <div class="base-tabs__item-border" :class="{ active: activeTab === index }"></div>
      </div>
    </div>
    <div class="base-tabs__border"></div>
  </div>
</template>
<script lang="js">
import { defineComponent, ref } from "vue";

export default defineComponent({
 name: "BaseTabs",
  props: {
    baseTabsList: {
      type: Array,
      default: () => [],
    }
  },
  setup(_, {emit}) {
    const activeTab = ref(0);
    const changeState = (activeIndex, activeElement) => {
      activeTab.value = activeIndex;
      emit("selectTab", activeElement);
    }
    return {
      activeTab,
      changeState
    }
  }
})
</script>
<style lang="scss" scoped>
.base-tabs {
  position: relative;
  margin: 44px 35px 0 35px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  color: #cbd5e1;
  &__list {
    position: relative;
    cursor: pointer;
    font-family: Inter, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    &.active {
      color: #0663c7;
    }
  }
  &__border {
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #e2e8f0;
  }
  &__item-border {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    height: 1px;
    width: 100%;
    z-index: 1;
    &.active {
      background-color: #0663c7;
    }
  }
  &__item {
    padding-bottom: 6px;
    color: var(--color-neutral-300);
    &.active {
      color: var(--color-primary-main);
    }
  }
  &__item:hover {
    color: #64748b;
    .base-tabs__item-border {
      background-color: #64748b;
    }
  }
}
</style>
