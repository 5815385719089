<template>
  <div class="list-group">
    <div class="list-group__title" @click="openDropDownMenu">
      {{ title }}
      <base-icon
        v-if="mobile"
        class="list-group__open-icon"
        :class="{ __opened: isDropdownOpen }"
        width="24"
        height="24"
        :path="'navBar/CaretUp.svg'"
      />
    </div>
    <div class="list-group__title-divider"></div>
    <transition name="fade">
      <div v-if="isDropdownOpen" class="list-group__item-wrapper">
        <list-group-item v-for="(item, index) in items" :item="item" :key="index" />
      </div>
    </transition>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { isMobile } from "@/lib/helpers/checkDevice";
import ListGroupItem from "@/components/ListGroup/components/ListGroupItem/ListGroupItem.vue";
import BaseIcon from "@/components/BaseIcon";

export default defineComponent({
  name: "ListGroup",
  components: { BaseIcon, ListGroupItem },
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    pointLength: {
      type: Number,
    },
  },
  setup(props) {
    const isDropdownOpen = ref(true);
    const mobile = computed(() => isMobile());
    const openDropDownMenu = () => {
      if (mobile.value) {
        isDropdownOpen.value = !isDropdownOpen.value;
      }
    };
    onMounted(() => {
      if (props.pointLength > 2 && mobile.value) {
        isDropdownOpen.value = false;
      } else {
        isDropdownOpen.value = true;
      }
    });
    return {
      mobile,
      isDropdownOpen,
      openDropDownMenu,
    };
  },
});
</script>
<style lang="scss" scoped>
.list-group {
  margin-bottom: 32px;
  width: 100%;
  max-width: 700px;
  &__title {
    color: #0f172a;
    font-family: Inter, serif;
    font-size: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 980px) {
    &__title {
      font-size: 18px;
    }
  }
  &__title-divider {
    margin-top: 8px;
    height: 1px;
    background: #0f172a;
    border-radius: 10px;
  }
  &__open-icon {
    margin-left: auto;
    filter: invert(52%) sepia(32%) saturate(1763%) hue-rotate(184deg) brightness(98%) contrast(93%);
    transition: ease-in 0.3s;
    &.__opened {
      transform: rotate(-180deg);
    }
  }
  &__item-wrapper {
    &.hide {
      animation: wrapperHide 0.5s forwards;
    }
    &.show {
      animation: wrapperShow 0.5s forwards;
    }
  }
  @keyframes wrapperHide {
    0% {
      opacity: 1;
      max-height: 2000px;
    }
    100% {
      opacity: 0;
      max-height: 0;
    }
  }
  @keyframes wrapperShow {
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 2000px;
    }
  }
}
.fade {
  &-enter-active,
  &-leave-active {
    transition: 0.3s ease-in-out;
  }
  &-enter,
  &-leave-to {
    height: 0;
    opacity: 0;
  }

  &-enter-to,
  &-leave {
    height: 100%;
    opacity: 1;
  }
}
</style>
