<template>
  <div class="day-template">
    <div class="heapC">
      <h2>Привет, {{ this.$root?.user?.nickname }}!</h2>
      <div v-if="pointAdminList.length">
        <h3>{{ `Ты повелитель ${pointAdminList.length > 1 ? pointAdminList.length : ""} точек` }}</h3>
        <div v-if="pointAdminList.length === 1">
          {{ pointAdminList[0] }}
        </div>
      </div>
      <h2>
        <template v-if="pathHidden">
          <span class="pointer" @click="followLink(pathHidden)">{{ title }}</span>
        </template>
        <template v-else>{{ title }}</template>
        {{
          new Intl.DateTimeFormat("ru-RU", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(dateIn)
        }}
        <span v-if="pathRightSide" class="pointer noselect" @click="followLink(pathRightSide)">
          &nbsp;>>>
        </span>
      </h2>
    </div>
    <div v-if="loading">
      <Loader />
    </div>
    <transition name="fade">
      <div v-if="!loading" class="day-template__time-wrapper">
        <BirthDay :date="dateIn" label="с Днём рождения!" labelPosition="left" />
        <BaseTabs
          v-if="!isMobile && baseTabsList.length > 0"
          :base-tabs-list="baseTabsList"
          @selectTab="filterPoints"
        />
        <div class="day-template__time-list">
          <list-group
            v-for="(point, index) in filteredPoints"
            :items="turnsAll.filter(item => item.id_point === point.id)"
            :point-length="filteredPoints.length"
            :title="point.nickname"
            :key="index"
          />
        </div>
      </div>
    </transition>
    <div>
      <!--      <img-->
      <!--        ref="whale"-->
      <!--        src="../assets/whale4.png"-->
      <!--        alt=""-->
      <!--        v-on:load="whaleOn()"-->
      <!--        class="whalehidden"-->
      <!--      />-->
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import BirthDay from "@/components/BirthDay.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";
import ListGroup from "@/components/ListGroup";
import BaseTabs from "@/components/BaseTabs";
import { isMobile } from "@/lib/helpers/checkDevice";

export default {
  name: "DayTemplate",
  components: { BaseTabs, ListGroup, Loader, BirthDay },
  props: {
    title: String,
    dateIn: Date,
    pathRightSide: String,
    pathHidden: String,
  },
  data() {
    return {
      loading: false,
      pointsAll: [],
      filteredPoints: [],
      turnsAll: [],
      pointAdminList: [],
      baseTabsList: ["Все"],
    };
  },

  async created() {
    this.loading = true;
    await this.tableDataRetrieve();
    this.loading = false;
  },
  computed: {
    isMobile,
  },

  methods: {
    offsetApply(h /*, l*/) {
      return offsetApply(h /*, l*/);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    followLink(href) {
      this.$router.push(href);
    },

    whaleOn() {
      // if (Math.random() * 4 > 3)
      //   setTimeout(() => (this.$refs.whale.classList = "whalemove"), 1000);
    },

    async tableDataRetrieve() {
      this.pointsAll = await request("/api/point/list_today");
      this.turnsAll = await request("/api/turn/list_all_for_date", "POST", {
        date: dateFormatJS(this.dateIn),
      });
      this.pointAdminList = await request("/api/user/adminpointlist", "POST", {
        id: this.$root?.user?.uid,
        data: "name",
      });
      // exclude empty points
      this.pointsAll = this.pointsAll.filter(p => {
        for (let i in this.turnsAll) if (this.turnsAll[i].id_point === p.id) return true;
      });
      this.filteredPoints = this.pointsAll;
      const pointsNickname = this.pointsAll.map(i => i.nickname);
      this.baseTabsList = [...(pointsNickname.length > 1 ? this.baseTabsList : []), ...pointsNickname];
    },
    filterPoints(activeFilter) {
      if (activeFilter !== "Все") {
        this.filteredPoints = this.pointsAll.filter(item => item.nickname === activeFilter);
      } else {
        this.filteredPoints = this.pointsAll;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.day-template {
  width: 100%;
  align-items: center;
  &__time-wrapper {
    width: 100%;
  }
  .heapC {
    margin-right: auto;
    padding-left: 32px;
  }
}
@media (max-width: 980px) {
  .day-template {
    padding: 0 15px;
    .heapC {
      margin-right: auto;
      padding-left: 0;
    }
  }
}
.fade-enter {
  margin-left: 32px;
  opacity: 0;
}
.fade-enter-active {
  transition: margin-left 0.5s, opacity 0.5s;
}
.day-template__time-list {
  width: 100%;
  align-items: center;
  margin-top: 30px;
}
</style>
